import React from 'react';
import Select from "react-select";
import {_t} from "../../../../js/translation";
import SelectFieldInput from "../select.field.input";

class QuestionSelectField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const question = this.props.question;
        const index = this.props.index;
        let options = question.config.map((value) => ({
            label: value.label,
            value: value.label
        }));
        return (
            <React.Fragment>
                <label className='label'>
                    {question.label} {question.required ? '*' : null}
                </label>

                <Select
                    components={{Input: SelectFieldInput}}
                    required={question.required}
                    isSearchable={true}
                    defaultValue={options.find(option => option.value === question.value)}
                    options={options}
                    classNamePrefix={'react-select'}
                    name={`answers[${index}]`}
                    data-uuid={question.uuid}
                    onChange={(value) => this.props.onChange(question.uuid, value.value)}
                />

                {this.props.errors && this.props.errors.hasOwnProperty('errors') && this.props.errors.errors.children.hasOwnProperty('answers') ?
                    <small className="form-error">{_t(this.props.errors.errors.children.answers.children[index].children.value.errors)}</small> : null}
            </React.Fragment>
        );
    }
}

export default QuestionSelectField;
